.player {
    position: relative;
    margin-bottom: 1.6rem;
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 450px;
    }   
    margin-left: auto;
    margin-right: auto;

    &__controls {
        display: flex;
        align-items: center;
        height: 3.125rem;
        padding: .5625rem 1.5rem .5625rem .75rem;
        text-align: center;
        border-radius: 100px;
        background-color: $primary;
        color: #fff;
    }

    button {
        margin: 0 5px;
    }

    &__control {
        border: 0;
        background: 0 0;
        color: inherit;
        padding: 5px;
        
        &:focus {
            outline: none;
        }
        svg {
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            fill: currentColor;
            
        }

    }
    &__button-icon {
        &--default {
            .player__control--pressed & {
                display: none;
            }
        }
        &--pressed {
            .player__control:not(.player__control--pressed) & {
                display: none; 
            }
        }
    }

    &__progress {
        position: relative;
        flex: 1;
        margin-left: 15px; //10px;
        margin-right: 14px;


        @mixin track {
            background: 0 0;
            border: 0;
            border-radius: 3px;
            height: 6px;
            // transition: box-shadow .3s ease;
            user-select: none;
            background-color: rgba(126, 126, 126, 0.2);
        }

        @mixin track-tab-focus {
            box-shadow: 0 0 0 5px rgba(126, 126, 126, 0.2);
            outline: 0
        }
        
        @mixin thumb {
            background: #fff;
            border: 0;
            border-radius: 100%;
            box-shadow: 0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2);
            height: 14px;
            position: relative;
            transition: all .2s ease;
            width: 14px;
        }

        input[type=range] {
            position: relative;
            z-index: 2;
            -webkit-appearance: none;
            background: 0 0;
            border: 0;
            border-radius: 28px;
            color: #fff;
            display: block;
            height: 20px;
            margin: 0;
            padding: 0;
            transition: box-shadow .3s ease;
            width: 100%;
            &:hover {
                cursor: pointer;
                // &::-webkit-slider-thumb {
                //     opacity: 1;
                // }
            }
            &:focus {
                outline: 0;
                &::-webkit-slider-runnable-track {
                    @include track-tab-focus;
                }
                &::-moz-range-track {
                    @include track-tab-focus;
                }
                &::-ms-track {
                    @include track-tab-focus;
                }
            }
            &:active {
                &::-webkit-slider-thumb {
                    box-shadow: 0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(0,0,0,.1);
                }
                &::-moz-range-thumb {
                    box-shadow: 0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(0,0,0,.1);
                }
                &::-ms-thumb {
                    box-shadow: 0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(0,0,0,.1);
                }
            }

                

            // -- WEBKIT
            
            &::-webkit-slider-runnable-track {
                @include track;
                -webkit-user-select: none;
                background-image: linear-gradient(90deg, currentColor var(--value, 0), transparent var(--value, 0));
                background-color: #ffffff33;
            }
        
            &::-webkit-slider-thumb {
                @include thumb;
                -webkit-appearance: none;
                margin-top: -4px;
                // opacity: 0;
            }
        
            // -- MOZ
        
            &::-moz-range-track {
                @include track;
                -moz-user-select: none;
            }
        
            &::-moz-range-thumb{
                @include thumb;
            }
        
            &::-moz-range-progress {
                background: currentColor;
                border-radius: 3px;
                height: 6px
            }
        
            &::-moz-focus-outer {
                border: 0;
            }
        

            // -- MS
        
            &::-ms-track {
                @include track;
                -ms-user-select: none;
                color: transparent
            }
        
            ::-ms-fill-upper {
                @include track;
                -ms-user-select: none;
        
            }
        
            &::-ms-fill-lower {
                background: 0 0;
                border: 0;
                border-radius: 3px;
                height: 6px;
                transition: box-shadow .3s ease;
                -ms-user-select: none;
                user-select: none;
                background: currentColor
            }
        
            &::-ms-thumb {
                @include thumb;
                margin-top: 0;
            }
        
            &::-ms-tooltip {
                display: none;
            }
        
        }


        &__buffer {
            position: absolute;
            left: 0;
            top: 50%;
            height: 6px;
            width: 100%;
            margin-top: -3px;
            padding: 0;
            border: 0;
            color: hsla(202, 15%, 72%, 0.659); //color: yellow;
            background: 0 0;
            border-radius: 100px;
        
           
            // -- WEBKIT
            
            &::-webkit-progress-bar {
                background: 0 0;
            }
            &::-webkit-progress-value {
                background: currentColor;
                border-radius: 100px;
            }


            // -- MOZ

            &::-moz-progress-bar {
                background: currentColor;
                border-radius: 100px;
            }


            // -- MS

            &::-ms-fill {
                border: 0;
                border-radius: 100px;
            }


            // backgrounds in different browsers: 
            // element itself             background in Firefox 
            // ::-moz-progress-bar        progress in Firefox
            // ::-webkit-progress-bar     background in Chrome, Safari
            // ::-webkit-progress-value   progress in Chrome, Safari
            // ::-ms-fill                 progress in IE10+, Edge
        }
    }
}