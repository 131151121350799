// For inline icons (instead of icon fonts)

.icon-block {
    display: block;
    height: 1em;
    width: 1em;

    path, use {
        fill: currentColor;
    
    }

}

.icon-inline {
    display: inline-block;
    position: relative;
    top: -.09em;
    height: 1em;
    width: 1em;
    // height: .9em;
    // width: .9em;
    vertical-align: middle;
    
    &-sm {
        height: .6em;
        width: .6em; 
        // top: -.05em;

    }

    &-btn {
        width: .9em;
        height: .9em;

    }

    &-xs {
        height: .4em;
        width: .4em; 

    }

    &-lg,
    &-player {
        height: 1.5em;
        width: 1.5em; 

    }

    path, use {
        fill: currentColor;

    }

}
   