.toast {
    margin-bottom: 0.75rem;

    &-wrapper {
        position: fixed; 
        bottom: 0; 
        right: 0; 
        
        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
                 
        z-index: $zindex-tooltip;
    }
}