.expl {
    &-link-wrapper {
        a:not(.expl-btn) {
            white-space: nowrap;
            color: inherit;
            position: relative;
            text-decoration: none;
            &:after {
                background-color: $color-1;
                content: "";
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                top: 100%;
                transition: all .2s ease;
                width: 100%;
                opacity: .5;
            }
            &:hover {
                text-decoration: none;
                &:after {
                    opacity: 1; 
                }
            }
        }
    }
    &-topic-1 {
        font-weight: $font-weight-normal;
        margin-top: 32px;
        margin-bottom: 24px;
        font-size: 24px;
        @include media-breakpoint-up(md) { font-size: 32px; }
    }
    &-topic-2 {
        @extend .expl-topic-1;
    }
    &-text {
        &_lead {
            font-size: $lead-font-size;
        }
    }
    &-img {
        img {
            max-width: 100%;
        }
    }
    &-text-colored {
        color: $color-1;
    }
    &-text-thinner {
        font-weight: $font-weight-light;
    }
    &-attention {
        font-size: 1.15rem;
        font-weight: $font-weight-light;
        &:before {
            margin-right: 5px;
               
                display: inline-block;
                width: 1em;
                height: 1em;
                vertical-align: middle;

                position: relative;
                top: -.05em;
                
                content: "";
                background: no-repeat center center;
                background-image: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#{$color-1}' d='M16,8A8,8,0,1,1,8,0,8,8,0,0,1,16,8ZM8,9.61A1.49,1.49,0,1,0,9.48,11.1,1.49,1.49,0,0,0,8,9.61ZM6.59,4.28l.24,4.39A.39.39,0,0,0,7.22,9H8.78a.39.39,0,0,0,.39-.36l.24-4.39A.4.4,0,0,0,9,3.87H7a.4.4,0,0,0-.39.41Z'/%3E%3C/svg%3E"), "#", "%23");
                background-size: 100% 100%;
        }
    }
    &-example {
        border-left: 1rem solid $color-1;
        background-color: lighten($color-1, 40%);
        background-color: #f3f3f3;
        padding: 15px;
        border-radius: 4px;
        margin-bottom: 24px;
        &__title {
            font-weight: $font-weight-light;
            font-size: 1.15rem;
            margin-bottom: 10px;
            &:before {
                margin-right: 5px;
               
                display: inline-block;
                width: 1em;
                height: 1em;
                vertical-align: middle;

                position: relative;
                top: -.05em;
                
                content: "";
                background: no-repeat center center;
                background-image: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#{$color-1}' d='M15.74,5.36,16,3.93a.42.42,0,0,0-.42-.5H12.9L13.42.5A.42.42,0,0,0,13,0H11.55a.42.42,0,0,0-.42.35l-.55,3.08H7.06L7.58.5A.43.43,0,0,0,7.16,0H5.7a.44.44,0,0,0-.42.35L4.73,3.43H1.91a.42.42,0,0,0-.42.35L1.23,5.21a.43.43,0,0,0,.42.5H4.32l-.81,4.58H.68a.43.43,0,0,0-.42.35L0,12.07a.42.42,0,0,0,.42.5H3.1L2.58,15.5A.42.42,0,0,0,3,16H4.45a.42.42,0,0,0,.42-.35l.55-3.08H8.94L8.42,15.5a.43.43,0,0,0,.42.5H10.3a.44.44,0,0,0,.42-.35l.55-3.08h2.82a.42.42,0,0,0,.42-.35l.26-1.43a.43.43,0,0,0-.42-.5H11.68l.81-4.58h2.83A.43.43,0,0,0,15.74,5.36ZM9.35,10.29H5.83l.82-4.58h3.52Z'/%3E%3C/svg%3E"), "#", "%23");
                background-size: 100% 100%;

            }
        }
        &__element {
            margin-bottom: 7px;
            &:last-child { margin-bottom: 0; }
        } 
    }
    &-list {
        li {
            margin-bottom: 7px;
            &:last-child { margin-bottom: 0; }
        }
    }
    &-table {
        margin-bottom: 24px;
        border-spacing: 2px;
        margin-left: -1 * $grid-gutter-width / 2;
        margin-right: -1 * $grid-gutter-width / 2;
        @include media-breakpoint-up(sm) {
            margin-left: 0;
            margin-right: 0;
            border-radius: 4px;
        }
        table {
            width: 100%;
            background-color: #f3f3f3;
            overflow: hidden;
            @include media-breakpoint-up(sm) {
                border-radius: 4px;
            }
            th {
                font-weight: $font-weight-bold;
                padding: 5px 16px;
                background-color: $color-1;
                color: white;
                border: 2px dotted rgba(255, 255, 255, 0.4) !important;
            }
            td {
                white-space: nowrap;
                padding: 5px 16px;
                border: 2px dotted white !important;
            }
            .cell-important {
                background-color: desaturate(lighten($color-1, 40%), 20%);
            }
            // Not used anymore
            // .cell-border-bottom {
            //     border-bottom: 2px dotted white !important;
            //     border-right: 2px dotted white !important;
            // }
            ul, ol {
                margin-bottom: 0;
            }
        }
    }
    &-btn {
        @extend .btn;
        @extend .btn-success;
        padding: .8em 2em;

    }

    &-btnblock {
        text-align: center;
        margin-top: 64px;
        margin-bottom: 64px;

    }
    
}