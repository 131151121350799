.noticePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1030;
	display: none;
	justify-content: center;
	align-items: center;
}
