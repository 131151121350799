//
//  E.g. fs-sm-3 or fs-xl-4
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (font-size: fs) {
      @each $size, $value in $font-sizes {

        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $value !important; }
        
      }
    }
  }

}
