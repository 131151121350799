// For large values of section-paddings
// Classes like "space-t-3", "space-t-md-5" etc.

$spaces: 
( 
    "0": $spacer * 0,
    "1": $spacer * 2,
    "2": $spacer * 4,
    "3": $spacer * 8,
    "4": $spacer * 12.5,
    "5": $spacer * 15
); 

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $prop, $abbrev in (padding: space) {
            @each $number, $value in $spaces {
                .#{$abbrev}-t#{$infix}-#{$number},
                .#{$abbrev}-y#{$infix}-#{$number} {
                  #{$prop}-top: $value !important;
                }
                .#{$abbrev}-b#{$infix}-#{$number},
                .#{$abbrev}-y#{$infix}-#{$number} {
                  #{$prop}-bottom: $value !important;
                }
            }
        }
    }
}